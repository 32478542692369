import React, { useState, useEffect } from 'react'
import MetaData from '../components/MetaData'
import { Helmet } from 'react-helmet'
import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/common/Hero'
import FeeSection from '../sections/landing1/FeeSection'
import { Section, Title, Text, Button, Box } from '../components/Core'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { theme } from '../utils'
import coinsOffered from '../assets/image/svg/coinsOffered.svg'
import styled from 'styled-components'


const StyledForm = styled.div`
  color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
  .hs-form-field {
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
  }
  .hs-input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 6px;
    background: ${props => (props.userDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)')}; 
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
    border-color: transparent;
    margin-bottom: 15px;
    margin-top: 15px
  }
  .hs-button {
    
    padding: 12px 24px;
    border-radius: 6px;
    background: #3377C6;
    border-color: transparent;
    margin-top: 10px;
    color: white;
  }
  #lc_message-33ed784f-9454-4b6d-9b1a-7a27f6f0bbab {
    height: 100px;
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
  }
  #lc_attachment-33ed784f-9454-4b6d-9b1a-7a27f6f0bbab {
    background: transparent;
  }
  .is-placeholder {
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
  }
  .hs-error-msg {
    color: ${props => (props.userDarkMode ? '#FB4C42' : '#D0433B')};
    margin-bottom: 15px;
  }
  .submitted-message {
    color: ${props => (props.userDarkMode ? '#599273' : '#2D844B')}; 
  }
  .hs-error-msgs inputs-list {
    color: ${props => (props.userDarkMode ? '#FB4C42' : '#D0433B')}; 
  }
  p {
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .hs_email label {
    margin-bottom: 0px;
  }
`

const Discover = () => {
  const { t, ready } = useTranslation(['discover'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const sectionBackgroundDark = userDarkMode
    ? theme.colors.black
    : theme.colors.lightGray


  const customFeeSection = {
    leftCol: '3',
    rightCol: '8',
    emailInput: false,
    background: theme.colors.bgDarkGray,
    title: t('ultraLow'),
  }

  const textColor = userDarkMode ? theme.colors.light : theme.colors.dark

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "20618924",
          formId: "2bb4820f-5eac-4df6-90bf-f82f9d26a585",
          target: '#hubspotForm1'
        })
      }
  }, [completed])


  const onButtonClick = () => {
    const element = document.getElementById("hbSection");
    const elementPosition = element.offsetTop;;
    const offsetPosition = elementPosition - 500;
    // `current` points to the mounted text input element
    element.scrollIntoView({ behavior: 'smooth', top: offsetPosition });
  };

  return ready && (
    <>
      <MetaData page="default" />
      <Helmet>
        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
      </Helmet>
      <PageWrapper headerDark footerDark>
        <Hero
          bg={sectionBackgroundDark}
          title={t('title')}
          firstP={t('firstP')}
          secondP={t('secondP')}
          img={true}
          imgSrc={coinsOffered}
          scrollButton={true}
          scrollOnClick={onButtonClick}
          scrollText={t('unlock')}
        />

        {/* Same coins : Section */}
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row className="pb-4">
              <Col lg="4">
                <Title variant="highlight" color={textColor}>
                  {t('sameCoins')}
                </Title>
              </Col>

              <Col lg="8">
                <Text color={textColor} mb={24}>
                    {t('lsOffers')}
                </Text>
                <Text color={textColor} mb={24}>
                    {t('trade')}
                </Text>
                <Text variant="strong" color={textColor} mb={24}>
                    {t('advantage')}
                </Text>
                <Button onClick={onButtonClick}  className="mt-3">
                  <Text color="#FAFAFA">
                      {t('unlock')}
                  </Text>
                </Button>
              </Col>
            </Row>
          </Container>
        </Section>

        {/* Pricing : Section */}
        <FeeSection
          config={customFeeSection}
        />

        {/* Real-time : Section */}
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row>
              <Col lg="7">
                <Title variant="highlight" color={textColor}>
                  {t('realTime')}
                </Title>
              </Col>
              <Col lg="5">
                <Text color={textColor}>
                    {t('tradeWith')}
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>

        {/* Hubspot form : Section */}
        <Section id="hbSection" bg={userDarkMode ? 'bgDarkGray' : 'light'}>
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <div className="text-center">
                  <Title
                      id="SignupTitle"
                      color={userDarkMode ? 'light' : 'dark'}
                      className="mb-2"
                    >
                      {t('ready')}
                    </Title>
                    <Text className="mb-5" variant="small" color={userDarkMode ? 'lightShade' : 'darkShade'}>
                      {t('signupText')}
                    </Text>
                    <StyledForm userDarkMode={userDarkMode} id="hubspotForm1"></StyledForm> 
                    <Text className="mt-4" variant="small" color={userDarkMode ? 'lightShade' : 'darkShade'}>
                      {t('terms')}
                    </Text>
                  </div>
                </Col>
              </Row>
            </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Discover
